import React from 'react';
import './bootstrap.css';
export default function Artists(){
 return (
   <>
   <body class="bg-dark">
   <header class="navbar fixed-top navbar-expand-md navbar-dark bd-navbar p-0"><nav class="container-xxl flex-wrap flex-md-nowrap bg-danger p-2" aria-label="Main navigation">            <a class="navbar-brand p-0 me-2" href="/" aria-label="Bootstrap">
   <img src="./prozabb-logo-96.png" width="48" height="48" class="rounded float-start" alt="..."/>
            </a>            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#bdNavbar" aria-controls="bdNavbar" aria-expanded="false" aria-label="Toggle navigation">
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" class="bi" fill="currentColor" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M2.5 11.5A.5.5 0 0 1 3 11h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 7h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 3h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"></path>
                </svg>

            </button><div class="collapse navbar-collapse" id="bdNavbar"><ul class="navbar-nav flex-row flex-wrap bd-navbar-nav pt-2 py-md-0">
                    <li class="nav-item col-6 col-md-auto">
                        <a class="nav-link p-2" href="/">Home</a>
                    </li>
                    <li class="nav-item col-6 col-md-auto">
                        <a class="nav-link p-2 active" aria-current="true" href="/docs/5.0/getting-started/introduction/" >Docs</a>
                    </li>
                    <li class="nav-item col-6 col-md-auto">
                        <a class="nav-link p-2" href="/docs/5.0/examples/" >Examples</a>
                    </li>
                </ul>
                <hr class="d-md-none text-white-50"/>
                <ul class="nav justify-content-end ms-auto">
                    <li class="text-white">แชร์</li>
                    <li class="ms-3"></li>
                    <li class="ms-3"></li>
                    <li class="ms-3"></li>
                    <li class="ms-3"></li>
                    <li class="ms-3"></li>
                </ul></div></nav></header>
                <main class="container bg-light">
        <div class="pt-3"></div>
        <div class="pt-5"></div>
        <div class="row bg-dark">
            <div class="pt-2">
                <img src="./truemove2.png" width="150" height="25" class="rounded float-start" alt="..."></img>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-8">
                <div>
                    <strong class="text-black">เลือกจำนวนวันใช้งาน</strong>
                    <div class="pb-2">
                        <button type="button" class="btn btn-outline-danger mb-1">โปรเน็ตขายดี</button>
                        <button type="button" class="btn btn-outline-danger mb-1">เน็ต 1 วัน</button>
                        <button type="button" class="btn btn-outline-danger mb-1">เน็ต 2 วัน</button>
                        <button type="button" class="btn btn-outline-danger mb-1">เน็ต 7 วัน</button>
                        <button type="button" class="btn btn-outline-danger mb-1">เน็ต 1 เดือน</button>
                        <button type="button" class="btn btn-outline-danger mb-1">เน็ต 3 - 12 เดือน</button>
                    </div>
                    <div class="ps-2 mb-1 border-bottom text-black fs-4 fw-bold">เน็ต 1 วัน</div>
                    <div class="container p-0">
                        <div class="row pt-3">
                            <div class="col-xl-6 pb-3">
                                <div class="card shadow">
                                    <div class="card-body text-secondary">
                                        <div class="d-flex justify-content-between pb-2">
                                            <div class="d-flex flex-column">
                                                <strong class="text-gray-dark">Facebook ไม่อั้น</strong>
                                            </div>
                                            <div class="d-flex">
                                                <div class="vr"></div>
                                                <strong class="ms-3 me-3 text-gray-dark">1<br/>วัน</strong>
                                                <div class="vr"></div>
                                                <strong class="ms-3 text-gray-dark">5<br/>บาท</strong>
                                            </div>
                                        </div>
                                        <div class="d-flex justify-content-between mb-1">
                                            <a data-bs-toggle="collapse" href="#collapse1" role="button" aria-expanded="false" aria-controls="collapse1">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-down-circle text-danger" viewBox="0 0 16 16">
                                                    <path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V4.5z" />
                                                </svg>
                                                <small>ดูรายละเอียด</small>
                                            </a>
                                            <small>ราคารวมภาษี 5.35 บาท</small>
                                        </div>
                                        <div class="collapse mb-1" id="collapse1">
                                            <div class="card card-body text-secondary">
                                                <ul>
                                                    <li>หากใช้แพ็กเกจเสริมโซเชียลไม่อั้นอยู่ จะไม่สามารถซื้อแพ็กเสริมนี้ได้</li>
                                                    <li>เฉพาะเล่น Facebook บนมือถือ ผ่านเว็บไซต์ หรือแอปพลิเคชันเท่านั้น</li>
                                                    <li>ระยะเวลาการใช้งานจะสิ้นสุดโดยอัตโนมัติหลัง 24 ชั่วโมง นับจากเวลาที่สมัคร</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <a href="foo" class="btn btn-danger d-grid  mb-1">กดสมัครที่นี่</a>
                                        <div class="d-flex justify-content-center text-gray-dark">
                                            หรือ กด *900*3967*17040536# <svg width="16" height="16" fill="currentColor" class="bi bi-telephone-outbound-fill" viewBox="0 0 16 16">
                                                <path fill-rule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511zM11 .5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V1.707l-4.146 4.147a.5.5 0 0 1-.708-.708L14.293 1H11.5a.5.5 0 0 1-.5-.5z" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-6 pb-3">
                                <div class="card shadow">
                                    <div class="card-body text-secondary">
                                        <div class="d-flex justify-content-between pb-2">
                                            <div class="d-flex flex-column">
                                                <strong class="text-gray-dark">Facebook ไม่อั้น</strong>
                                            </div>
                                            <div class="d-flex">
                                                <div class="vr"></div>
                                                <strong class="ms-3 me-3 text-gray-dark">1<br/>วัน</strong>
                                                <div class="vr"></div>
                                                <strong class="ms-3 text-gray-dark">5<br/>บาท</strong>
                                            </div>
                                        </div>
                                        <div class="d-flex justify-content-between mb-1">
                                            <a data-bs-toggle="collapse" href="#collapse2" role="button" aria-expanded="false" aria-controls="collapse2">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-down-circle text-danger" viewBox="0 0 16 16">
                                                    <path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V4.5z" />
                                                </svg>
                                                <small>ดูรายละเอียด</small>
                                            </a>
                                            <small>ราคารวมภาษี 5.35 บาท</small>
                                        </div>
                                        <div class="collapse mb-1" id="collapse2">
                                            <div class="card card-body text-secondary">
                                                <ul>
                                                    <li>หากใช้แพ็กเกจเสริมโซเชียลไม่อั้นอยู่ จะไม่สามารถซื้อแพ็กเสริมนี้ได้</li>
                                                    <li>เฉพาะเล่น Facebook บนมือถือ ผ่านเว็บไซต์ หรือแอปพลิเคชันเท่านั้น</li>
                                                    <li>ระยะเวลาการใช้งานจะสิ้นสุดโดยอัตโนมัติหลัง 24 ชั่วโมง นับจากเวลาที่สมัคร</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <a href="foo" class="btn btn-danger d-grid  mb-1">กดสมัครที่นี่</a>
                                        <div class="d-flex justify-content-center text-gray-dark">
                                            หรือ กด *900*3967*17040536# <svg width="16" height="16" fill="currentColor" class="bi bi-telephone-outbound-fill" viewBox="0 0 16 16">
                                                <path fill-rule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511zM11 .5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V1.707l-4.146 4.147a.5.5 0 0 1-.708-.708L14.293 1H11.5a.5.5 0 0 1-.5-.5z" />
                                            </svg>
                                            </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
                            <div class="col-sm-4 text-secondary">
                <div class="pt-2"></div>
                <strong class="text-black">วิธีการสมัคร</strong>
                <ul>
                    <li>เลือกจำนวนวันใช้งาน</li>
                    <li>เลือกโปรที่ต้องการ</li>
                    <li>เช็คยอดเงิน กด <a href="tel:*123*1#" class="text-danger">*123#</a> โทร</li>
                    <li>กดปุ่มสมัครแล้วโทรออก</li>
                    <li>หรือกดรหัสการสมัครแล้วโทรออก</li>
                    <li>รอรับ SMS ยืนยัน</li>
                </ul>
                <strong class="text-black">เบอร์น่ารู้</strong>
                <ul>
                    <li>ตรวจสอบหมายเลข กด <a href="tel:*933#" class="text-danger">*933#</a> โทร</li>
                    <li>เช็คยอดเงิน โบนัส และวันใช้งาน กด <a href="tel:*123#" class="text-danger">*123#</a> โทร</li>
                    <li>เช็คยอดนาทีโทรฟรี กด <a href="tel:*123*1#" class="text-danger">*123*1#</a> โทร</li>
                    <li>เช็คยอด Internet  Wi-Fi กด <a href="tel:*900#" class="text-danger">*900#</a> โทร</li>
                    <li>เช็คยอด SMS คงเหลือ กด <a href="tel:*123*2#" class="text-danger">*123*2#</a> โทร</li>
                    <li>เปิดบริการ Internet กด <a href="tel:*902*01#" class="text-danger">*902*01#</a> โทร</li>
                    <li>ปิดบริการ Internet กด <a href="tel:*902*02#" class="text-danger">*902*02#</a> โทร</li>
                    <li>การขอรหัสการใช้งาน WiFi กด <a href="tel:*871*4#" class="text-danger">*871*4#</a> โทร</li>
                </ul>
                <span class="text-danger">* หมายเหตุ</span> เลขหมายข้างต้น ไม่คิดค่าบริการ
            </div>
            <div>
                <strong>ติดต่อ Call Center โทร. <span class="text-danger">1242</span></strong>
            </div>
        </div><div class="container">
            <footer class="d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top">
                <div class="col-md-4 d-flex align-items-center">
                    <a href="/" class="mb-3 me-2 mb-md-0 text-muted text-decoration-none lh-1">
                        <svg class="bi" width="30" height="24">
                        </svg>
                    </a>
                    <span class="text-muted">© 2021 Prozabb.com</span>
                </div>
                <ul class="nav col-md-4 justify-content-end list-unstyled d-flex">
                    <li>แชร์</li>
                    <li class="ms-3"></li>
                    <li class="ms-3"></li>
                    <li class="ms-3">
                    </li>
                    <li class="ms-3"></li>
                    <li class="ms-3"></li>
                </ul>
            </footer>
        </div>
    </main>
    </body>
   </>
);
}